.squarelink {
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	width: 258.96px;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: -2px 10px 5px rgba(0, 0, 0, 0);
	border-radius: 10px;
	font-family: SQ Market, Helvetica, Arial, sans-serif;
}

.squarelink2 {
	padding: 20px;
}
.squarelink3 {
	display: inline-block;
	font-size: 18px;
	line-height: 48px;
	height: 48px;
	color: #ffffff;
	min-width: 212px;
	background-color: #000000;
	text-align: center;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}
