@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);


* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

body {
	font-family: 'Open Sans', sans-serif;
	background: #fff;
	color: #333;
	line-height: 1.6;
}

ul {
	list-style: none;
}

a {
	color: #333;
	text-decoration: none;
}

h1,
h2 {
	font-weight: 300;
	line-height: 1;
}

p {
	margin: 10px 0;
}

img {
	width: 100%;
}

/* Padding */

.p-1 {
	padding: 10px;
}
.p-2 {
	padding: 15px;
}
.p-3 {
	padding: 20px;
}
.p-4 {
	padding: 25px;
}

/* Margin */

.m-1 {
	margin: 10px;
}
.m-2 {
	margin: 15px;
}
.m-3 {
	margin: 20px;
}
.m-4 {
	margin: 25px;
}

/* Grey out element */

.grey-out {
	opacity: 0.2;
	filter: alpha(opacity=40);
	background-color: #000;
}

.noCursor {
	cursor: not-allowed;
}

/* Text colors */

.text-primary {
	color: #74583d;
}

.text-secondary {
	color: #f2d1b7;
}

/* Button */

.btn {
	cursor: pointer;
	display: inline-block;
	padding: 10px 30px;
	color: #fff;
	background-color: #74583d;
	border: none;
	border-radius: 5px;
}

.btn:hover {
	opacity: 0.9;
}

.btn-primary,
.bg-primary {
	background: #74583d;
	color: #fff;
}
.btn-secondary,
.bg-secondary {
	background: #9d7248;
	color: #fff;
}
.btn-dark,
.bg-dark {
	background: #333;
	color: #fff;
}
.btn-light,
.bg-light {
	background: #f4f4f4;
	color: #333;
}

.btn-outline {
	background: transparent;
	border: 1px solid #fff;
}

/* Flex items */

.flex-items {
	display: flex;
	padding: 0px;
	text-align: center;
	justify-content: center;
	text-align: center;
	height: 100%;
}

.flex-items > div {
	padding: 5px;
	margin: 5px;
}

/* Flex Columns */

.flex-columns.flex-reverse .row {
	flex-direction: row-reverse;
}

.flex-columns .row {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.flex-columns .column {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex: 1 1;
}

.flex-columns .column .column-1,
.flex-columns .column .column-2 {
	height: 100%;
}

.flex-columns img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.flex-columns .column-2 {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 30px;
}

/* Section header */
.section-header {
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.section-header h2 {
	font-size: 40px;
	margin: 20px 0;
}

.section-padding {
	padding: 20px 20px 40px;
}

.flex-grid .row {
	display: flex;
	flex-wrap: wrap;
	padding: 0 4px;
}

.flex-grid .column {
	flex: 25% 1;
	max-width: 25%;
	padding: 0 4px;
}

.clickable {
	height: 100%;
	width: 100%;
	/* left: 0;
	top: 0; */
	position: absolute;
	z-index: 1;
}

/* Navbar */

.navbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #644d3e;
	color: #fff;
	opacity: 0.9;
	width: 100%;
	height: 80px;
	position: fixed;
	top: 0;
	padding: 0 30px;
	padding-bottom: 10px;
}

.navbar a {
	color: #fff;
	padding: 10px 5px;
	margin: 0 5px;
}

.navbar ul {
	display: flex;
}

.MDALogo {
	display: flex;
	align-items: center;
	margin: 5px;
}

.MDALogo img {
	width: 70px;
}
.MDALogo h1,
h2 {
	font-weight: 400;
	margin: 15px;
}

.navbar a:hover {
	border-bottom: #741418 2px solid;
}

.footer {
	position: fixed;
	bottom: 0;
	color: #fff;
	width: 100%;
}

.copyright {
	text-align: center;
	font-size: 0.7em;
}

/* Icons */

.icons {
	padding: 15px;
}

.icon-card {
	background-color: #cdcdb7;
	width: 25%;
	height: auto;
	border-radius: 10%;
	font-size: 0.8em;
	padding: 15px;
}

.flex-cards {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: 10px;
	text-align: center;
	justify-content: center;
	text-align: center;
	height: 100%;
}

.new-card {
	background-color: #cdcdb7;
	width: 230px;
	/* height: 220px; */
	border-radius: 10%;
	font-size: 0.7em;
	padding: 10px;
	margin: 10px;
}

.mixedcase {
	display: flex;
	text-align: center;
	justify-content: center;
}

.mixedcase-card {
	background-color: #cdcdb7;
	width: 550px;
	/* height: 220px; */
	border-radius: 15px;
	font-size: 0.7em;
	padding: 10px;
	margin: 10px;
	align-content: center;
	text-align: center;
	align-items: center;
	font-weight: bold;
}

.mixedcase-title {
	display: grid;
	grid-template-columns: 150px 380px;
	font-size: large;
	padding: 10px;
	align-content: center;
}

.mixedcase-title img {
	width: 100px;
	height: 100px;
	margin: 20px;
}

.mixedcase-maincontent {
	display: grid;
	grid-template-columns: 240px 270px;
	align-content: center;
}

.mixedcase-showcase {
	display: grid;
	grid-template-columns: 80px 85px;
	padding: 5px;
}
.mixedcase-showcase img {
	width: 90%;
	margin: 0px;
	object-fit: contain;
	align-items: end;
}

.new-card img {
	width: 150px;
}

.new-card-price {
	font-size: 1.2em;
	font-weight: bold;
}

.icons h3 {
	font-weight: bold;
	margin-bottom: 5px;
}

.icon-card img {
	height: 80px;
	width: 80px;
	background-color: #b99163;
	color: #fff;
	padding: 1rem;
	border-radius: 30%;
	margin-bottom: 5px;
}

.cases img:hover {
	opacity: 0.7;
}

.category-cards {
	width: 200px;
	background-color: aqua;
}

.solutions {
	padding: 15px;
}

.partner-logos {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 5px;
	text-align: center;
	justify-content: center;
	width: 100%;
}

.partner-logos-box {
	/* flex: 1; */
	/* height: 100px; */
	width: 90px;
	padding: 5px;
	margin: 5px;
	border-radius: 5px;
	background-color: #cdcdb7;
}

.partner-logos-span2 {
	height: 100px;
	width: 190px;
}

.listings {
	color: #cdcdb7;
}

.about-sig {
	font-style: italic;
}
.about-sig img {
	width: 200px;
}

.contact {
	display: flex;
	flex-direction: column;
	padding-bottom: 30px;
	text-align: center;
	justify-content: center;
	text-align: center;
	height: 100%;
	color: #fff;
}

@media only screen and (max-width: 600px) {
	/* * {
		float: none;
		width: 100%;
	} */
	.navbar {
		flex-direction: column;
		height: 100px;
	}
}

@media only screen and (max-width: 480px) {
	/* * {
		float: none;
		width: 100%;
	} */
	.navbar {
		flex-direction: column;
		height: 130px;
	}
} ;

/* Header */

.hero {
	background: url(/static/media/showcase.c2f89574.jpg) no-repeat center center/cover;
	height: 100vh;
	position: relative;
	color: #fff;
}

.hero .content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0 20px;
	height: 100%;
}

.hero .content h1 {
	font-size: 55px;
}
.hero .content p {
	font-size: 23px;
	max-width: 600px;
	margin: 20px 0 30px;
}

.hero::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
}

.hero * {
	z-index: 10;
}

.landing-provs {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
}

.select-prov {
	width: 120px;
	height: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	margin: 5px;
	background-color: rgba(205, 205, 183, 0.7);
	border-radius: 10px;
}

.select-prov img {
	width: 75px;
}

.two-col {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.catalogSection {
	display: flex;
	padding: 3px;
	align-items: center;
	font-weight: bold;
	line-height: 90%;
}
.catalogSection img {
	width: 24px;
	height: 16px;
	margin: 0 10px;
}

.catalog {
	display: grid;
	grid-template-columns: 1fr 1fr;
	border-radius: 5px;
	text-align: center;
	width: 950px;
	height: 580px;
	/* was 580px */
	margin: 0;
	padding: 7px;
	/* background-color: #b99163; */
}

.catalog-col {
	width: 100%;
	border-radius: 5px;
	padding-top: 5px;
	/* background-color: #cdcdb7; */
	position: relative;
}

/* CATALOG TITLE */

.catalogTitle {
	font-weight: bold;
	line-height: 100%;
	padding: 10px;
	font-size: 20px;
}

.logo {
	width: 200px;
	padding: 5px;
}

.catalog-item {
	/* background-color: chartreuse; */
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	margin: 5px;
	font-size: 12px;
	line-height: 100%;
	text-align: start;
}

.catalog-item img {
	width: 70px;
	align-items: center;
}

.cie:first-child {
	grid-column: 1 / span 3;
	grid-row: 1 / span 2;
}
.cie:nth-child(2) {
	padding-left: 5px;
	grid-column: 3 / span 6;
	grid-row: 1 / span 1;
}
.cie:nth-child(4) {
	font-weight: bold;
}

.cie:nth-child(5) {
	color: blue;
	font-style: italic;
	padding-left: 5px;
	grid-column: 3 / span 7;
	grid-row: 2 / span 1;
}

.cie:nth-child(6) {
	color: blue;
	font-weight: bold;
}

.catalogFooter {
	font-size: 0.6em;
	position: absolute;
	bottom: 0;
	padding-left: 15px;
}

.overlay {
	position: absolute;

	font-size: 15px;
	color: rgb(226, 21, 21);

	-webkit-transform: translate(180px, -15px) rotate(-5deg);

	        transform: translate(180px, -15px) rotate(-5deg);
	z-index: 3;
	text-align: center;
}

@media only screen and (max-width: 600px) {
	.catalog {
		grid-template-columns: 1fr;
		height: 1200;
	}
} ;

.ONHero {
	background: url(/static/media/banner.e82ecf2b.jpg) no-repeat center center/cover;
	height: 120px;
	position: relative;
	color: #fff;
}

.ONHero .content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0 20px;
	height: 100%;
}

.ONHero .content h1 {
	font-size: 55px;
}
.ONHero .content p {
	font-size: 23px;
	max-width: 600px;
	margin: 20px 0 30px;
}

.ONHero::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
}

.ONHero * {
	z-index: 10;
}

.ONHero {
	background: url(/static/media/banner.e82ecf2b.jpg) no-repeat center center/cover;
	height: 120px;
	position: relative;
	color: #fff;
}

.ONHero .content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0 20px;
	height: 100%;
}

.ONHero .content h1 {
	font-size: 55px;
}
.ONHero .content p {
	font-size: 23px;
	max-width: 600px;
	margin: 20px 0 30px;
}

.ONHero::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
}

.ONHero * {
	z-index: 10;
}

.squarelink {
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	width: 258.96px;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: -2px 10px 5px rgba(0, 0, 0, 0);
	border-radius: 10px;
	font-family: SQ Market, Helvetica, Arial, sans-serif;
}

.squarelink2 {
	padding: 20px;
}
.squarelink3 {
	display: inline-block;
	font-size: 18px;
	line-height: 48px;
	height: 48px;
	color: #ffffff;
	min-width: 212px;
	background-color: #000000;
	text-align: center;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

