.ONHero {
	background: url('../../images/home/banner.jpg') no-repeat center center/cover;
	height: 120px;
	position: relative;
	color: #fff;
}

.ONHero .content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0 20px;
	height: 100%;
}

.ONHero .content h1 {
	font-size: 55px;
}
.ONHero .content p {
	font-size: 23px;
	max-width: 600px;
	margin: 20px 0 30px;
}

.ONHero::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
}

.ONHero * {
	z-index: 10;
}
