.two-col {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.catalogSection {
	display: flex;
	padding: 3px;
	align-items: center;
	font-weight: bold;
	line-height: 90%;
}
.catalogSection img {
	width: 24px;
	height: 16px;
	margin: 0 10px;
}

.catalog {
	display: grid;
	grid-template-columns: 1fr 1fr;
	border-radius: 5px;
	text-align: center;
	width: 950px;
	height: 580px;
	/* was 580px */
	margin: 0;
	padding: 7px;
	/* background-color: #b99163; */
}

.catalog-col {
	width: 100%;
	border-radius: 5px;
	padding-top: 5px;
	/* background-color: #cdcdb7; */
	position: relative;
}

/* CATALOG TITLE */

.catalogTitle {
	font-weight: bold;
	line-height: 100%;
	padding: 10px;
	font-size: 20px;
}

.logo {
	width: 200px;
	padding: 5px;
}

.catalog-item {
	/* background-color: chartreuse; */
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	margin: 5px;
	font-size: 12px;
	line-height: 100%;
	text-align: start;
}

.catalog-item img {
	width: 70px;
	align-items: center;
}

.cie:first-child {
	grid-column: 1 / span 3;
	grid-row: 1 / span 2;
}
.cie:nth-child(2) {
	padding-left: 5px;
	grid-column: 3 / span 6;
	grid-row: 1 / span 1;
}
.cie:nth-child(4) {
	font-weight: bold;
}

.cie:nth-child(5) {
	color: blue;
	font-style: italic;
	padding-left: 5px;
	grid-column: 3 / span 7;
	grid-row: 2 / span 1;
}

.cie:nth-child(6) {
	color: blue;
	font-weight: bold;
}

.catalogFooter {
	font-size: 0.6em;
	position: absolute;
	bottom: 0;
	padding-left: 15px;
}

.overlay {
	position: absolute;

	font-size: 15px;
	color: rgb(226, 21, 21);

	transform: translate(180px, -15px) rotate(-5deg);
	z-index: 3;
	text-align: center;
}

@media only screen and (max-width: 600px) {
	.catalog {
		grid-template-columns: 1fr;
		height: 1200;
	}
} ;
