/* Navbar */

.navbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #644d3e;
	color: #fff;
	opacity: 0.9;
	width: 100%;
	height: 80px;
	position: fixed;
	top: 0;
	padding: 0 30px;
	padding-bottom: 10px;
}

.navbar a {
	color: #fff;
	padding: 10px 5px;
	margin: 0 5px;
}

.navbar ul {
	display: flex;
}

.MDALogo {
	display: flex;
	align-items: center;
	margin: 5px;
}

.MDALogo img {
	width: 70px;
}
.MDALogo h1,
h2 {
	font-weight: 400;
	margin: 15px;
}

.navbar a:hover {
	border-bottom: #741418 2px solid;
}

.footer {
	position: fixed;
	bottom: 0;
	color: #fff;
	width: 100%;
}

.copyright {
	text-align: center;
	font-size: 0.7em;
}

/* Icons */

.icons {
	padding: 15px;
}

.icon-card {
	background-color: #cdcdb7;
	width: 25%;
	height: auto;
	border-radius: 10%;
	font-size: 0.8em;
	padding: 15px;
}

.flex-cards {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: 10px;
	text-align: center;
	justify-content: center;
	text-align: center;
	height: 100%;
}

.new-card {
	background-color: #cdcdb7;
	width: 230px;
	/* height: 220px; */
	border-radius: 10%;
	font-size: 0.7em;
	padding: 10px;
	margin: 10px;
}

.mixedcase {
	display: flex;
	text-align: center;
	justify-content: center;
}

.mixedcase-card {
	background-color: #cdcdb7;
	width: 550px;
	/* height: 220px; */
	border-radius: 15px;
	font-size: 0.7em;
	padding: 10px;
	margin: 10px;
	align-content: center;
	text-align: center;
	align-items: center;
	font-weight: bold;
}

.mixedcase-title {
	display: grid;
	grid-template-columns: 150px 380px;
	font-size: large;
	padding: 10px;
	align-content: center;
}

.mixedcase-title img {
	width: 100px;
	height: 100px;
	margin: 20px;
}

.mixedcase-maincontent {
	display: grid;
	grid-template-columns: 240px 270px;
	align-content: center;
}

.mixedcase-showcase {
	display: grid;
	grid-template-columns: 80px 85px;
	padding: 5px;
}
.mixedcase-showcase img {
	width: 90%;
	margin: 0px;
	object-fit: contain;
	align-items: end;
}

.new-card img {
	width: 150px;
}

.new-card-price {
	font-size: 1.2em;
	font-weight: bold;
}

.icons h3 {
	font-weight: bold;
	margin-bottom: 5px;
}

.icon-card img {
	height: 80px;
	width: 80px;
	background-color: #b99163;
	color: #fff;
	padding: 1rem;
	border-radius: 30%;
	margin-bottom: 5px;
}

.cases img:hover {
	opacity: 0.7;
}

.category-cards {
	width: 200px;
	background-color: aqua;
}

.solutions {
	padding: 15px;
}

.partner-logos {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 5px;
	text-align: center;
	justify-content: center;
	width: 100%;
}

.partner-logos-box {
	/* flex: 1; */
	/* height: 100px; */
	width: 90px;
	padding: 5px;
	margin: 5px;
	border-radius: 5px;
	background-color: #cdcdb7;
}

.partner-logos-span2 {
	height: 100px;
	width: 190px;
}

.listings {
	color: #cdcdb7;
}

.about-sig {
	font-style: italic;
}
.about-sig img {
	width: 200px;
}

.contact {
	display: flex;
	flex-direction: column;
	padding-bottom: 30px;
	text-align: center;
	justify-content: center;
	text-align: center;
	height: 100%;
	color: #fff;
}

@media only screen and (max-width: 600px) {
	/* * {
		float: none;
		width: 100%;
	} */
	.navbar {
		flex-direction: column;
		height: 100px;
	}
}

@media only screen and (max-width: 480px) {
	/* * {
		float: none;
		width: 100%;
	} */
	.navbar {
		flex-direction: column;
		height: 130px;
	}
} ;
