/* Header */

.hero {
	background: url('../images/home/showcase.jpg') no-repeat center center/cover;
	height: 100vh;
	position: relative;
	color: #fff;
}

.hero .content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0 20px;
	height: 100%;
}

.hero .content h1 {
	font-size: 55px;
}
.hero .content p {
	font-size: 23px;
	max-width: 600px;
	margin: 20px 0 30px;
}

.hero::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
}

.hero * {
	z-index: 10;
}

.landing-provs {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
}

.select-prov {
	width: 120px;
	height: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	margin: 5px;
	background-color: rgba(205, 205, 183, 0.7);
	border-radius: 10px;
}

.select-prov img {
	width: 75px;
}
