@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

body {
	font-family: 'Open Sans', sans-serif;
	background: #fff;
	color: #333;
	line-height: 1.6;
}

ul {
	list-style: none;
}

a {
	color: #333;
	text-decoration: none;
}

h1,
h2 {
	font-weight: 300;
	line-height: 1;
}

p {
	margin: 10px 0;
}

img {
	width: 100%;
}

/* Padding */

.p-1 {
	padding: 10px;
}
.p-2 {
	padding: 15px;
}
.p-3 {
	padding: 20px;
}
.p-4 {
	padding: 25px;
}

/* Margin */

.m-1 {
	margin: 10px;
}
.m-2 {
	margin: 15px;
}
.m-3 {
	margin: 20px;
}
.m-4 {
	margin: 25px;
}

/* Grey out element */

.grey-out {
	opacity: 0.2;
	filter: alpha(opacity=40);
	background-color: #000;
}

.noCursor {
	cursor: not-allowed;
}

/* Text colors */

.text-primary {
	color: #74583d;
}

.text-secondary {
	color: #f2d1b7;
}

/* Button */

.btn {
	cursor: pointer;
	display: inline-block;
	padding: 10px 30px;
	color: #fff;
	background-color: #74583d;
	border: none;
	border-radius: 5px;
}

.btn:hover {
	opacity: 0.9;
}

.btn-primary,
.bg-primary {
	background: #74583d;
	color: #fff;
}
.btn-secondary,
.bg-secondary {
	background: #9d7248;
	color: #fff;
}
.btn-dark,
.bg-dark {
	background: #333;
	color: #fff;
}
.btn-light,
.bg-light {
	background: #f4f4f4;
	color: #333;
}

.btn-outline {
	background: transparent;
	border: 1px solid #fff;
}

/* Flex items */

.flex-items {
	display: flex;
	padding: 0px;
	text-align: center;
	justify-content: center;
	text-align: center;
	height: 100%;
}

.flex-items > div {
	padding: 5px;
	margin: 5px;
}

/* Flex Columns */

.flex-columns.flex-reverse .row {
	flex-direction: row-reverse;
}

.flex-columns .row {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.flex-columns .column {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex: 1;
}

.flex-columns .column .column-1,
.flex-columns .column .column-2 {
	height: 100%;
}

.flex-columns img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.flex-columns .column-2 {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 30px;
}

/* Section header */
.section-header {
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.section-header h2 {
	font-size: 40px;
	margin: 20px 0;
}

.section-padding {
	padding: 20px 20px 40px;
}

.flex-grid .row {
	display: flex;
	flex-wrap: wrap;
	padding: 0 4px;
}

.flex-grid .column {
	flex: 25%;
	max-width: 25%;
	padding: 0 4px;
}

.clickable {
	height: 100%;
	width: 100%;
	/* left: 0;
	top: 0; */
	position: absolute;
	z-index: 1;
}
